<template>
  <div class="permission">
    <div class="table">
      <el-table
        height="420px"
        border
        size="medium"
        row-key="id"
        default-expand-all
        :cell-style="{ 'text-align': 'left' }"
        :header-cell-style="{ 'text-align': 'center' }"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :data="productsList"
      >
        <el-table-column label="菜单" width="200">
          <template slot-scope="scope">
            <div class="centerCheck">
              <el-checkbox
                :disabled="!permissionsRow.tenantId"
                :value="scope.row.permission == '1' ? true : false"
                @change="demo($event, scope.row)"
              >
                {{ scope.row.name }}
              </el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="功能">
          <template slot-scope="scope">
            <div class="centerCheck">
              <el-checkbox
                :disabled="!permissionsRow.tenantId"
                :value="item.permission == '1' ? true : false"
                v-for="(item, index) in scope.row.operations"
                :key="index"
                @change="demoChik($event, scope.row, index)"
              >
                {{ item.name }}
              </el-checkbox>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { listRecursion } from "@/utils/convert";
export default {
  props: ["functionId", "permissionsRow"],
  data() {
    return {
      productsList: [],
      roleIds: [],
    };
  },
  methods: {
    getAllMenus() {
      this.$axios.get("menus", { productId: "1626834932245524482" }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          let { menuIds, objectPermissions } = this.permissionsRow;
          for (let z = 0; z < res.data.length; z++) {
            for (let j = 0; j < menuIds.length; j++) {
              if (res.data[z].id == menuIds[j]) {
                this.$set(res.data[z], "permission", "1");
              }
            }
            for (let g = 0; g < objectPermissions.length; g++) {
              if (res.data[z].requiredAuthority == objectPermissions[g]) {
                this.$set(res.data[z], "permission", "1");
              }
            }
          }

          let list = this.filterType(res.data);
          this.productsList = listRecursion(list);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    filterType(list) {
      let menus = [];
      let btns = [];
      for (let i = 0; i < list.length; i++) {
        if (!list[i].permission) {
          this.$set(list[i], "permission", "");
        }
        if (list[i].type == "MENU") {
          menus.push(list[i]);
        } else {
          btns.push(list[i]);
        }
      }
      for (let j = 0; j < menus.length; j++) {
        this.$set(menus[j], "operations", []);
        for (let s = 0; s < btns.length; s++) {
          if (menus[j].id == btns[s].parentId) {
            menus[j].operations.push(btns[s]);
          }
        }
      }
      return menus;
    },
    demo(eve, data) {
      if (data.children) {
        this.getMenu(eve, data);
      } else {
        data.permission = JSON.stringify(Number(eve));
        if (data.operations && data.operations.length > 0) {
          for (let i = 0; i < data.operations.length; i++) {
            data.operations[i].permission = JSON.stringify(Number(eve));
          }
        }
      }
    },
    // 复选菜单
    getMenu(eve, data) {
      data.permission = JSON.stringify(Number(eve));
      data.children.forEach((el) => {
        el.permission = JSON.stringify(Number(eve));
        if (el.operations && el.operations.length > 0) {
          el.operations.forEach((evl) => {
            evl.permission = JSON.stringify(Number(eve));
          });
        }
        if (el.children) {
          this.getMenu(eve, el);
        }
      });
    },
    demoChik(eve, row, index) {
      row.operations[index].permission = JSON.stringify(Number(eve));
      let isOperations = false;
      isOperations = row.operations.some((el) => {
        if (el.permission == "1") {
          return true;
        }
      });
      if (isOperations) {
        row.permission = JSON.stringify(Number(1));
      }
    },
    functionSubmit() {
      let data = JSON.parse(JSON.stringify(this.productsList));
      this.roleIds = [];
      for (let j = 0; j < data.length; j++) {
        if (data[j].children) {
          this.forData(data[j]);
        }
        this.roleIds.push(data[j]);
      }
      let menuIds = [];
      let permissions = [];
      for (let s = 0; s < this.roleIds.length; s++) {
        if (this.roleIds[s].permission == "1") {
          menuIds.push(this.roleIds[s].id);
        }
        if (this.roleIds[s].operations.length > 0) {
          for (let z = 0; z < this.roleIds[s].operations.length; z++) {
            if (this.roleIds[s].operations[z].permission == "1") {
              if (this.roleIds[s].operations[z].requiredAuthority) {
                permissions.push(
                  this.roleIds[s].operations[z].requiredAuthority
                );
              }
            }
          }
        }
      }
      this.$axios.restJsonPost(
        "rolesPmoMenusEdit",
        {
          id: this.functionId,
        },
        { menuIds: menuIds, objectPermissions: permissions },
        (res) => {
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    forData(data) {
      for (let s = 0; s < data.children.length; s++) {
        if (data.children[s].children) {
          this.forData(data.children[s]);
        }
        this.roleIds.push(data.children[s]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.permission {
  display: flex;
  justify-content: space-between;
  .table {
    width: 100%;
    .centerCheck {
      display: inline-block;
    }
  }
}
</style>
